<template>
  <div>
    <transition name="fade">
      <b-card no-body
              v-if="
              (
                  isSales &&
                  Object.entries(salesIndicator).length !== 0
              ) || (
                  !isSales &&
                  Object.entries(expensesIndicator).length !== 0
              )">
        <b-card-header class="align-items-baseline">
          <div>
            <b-card-title class="mb-25 d-flex">
              <span v-if="isInvoiced"
                    class="my-auto">{{ $t('Invoiced') }}
              </span>
              <span v-else
                    class="my-auto">{{ $t('Ordered') }}
              </span>
              <div class="d-inline-flex ml-2">
                <b-alert
                    show
                    variant="primary"
                    class="d-inline mb-0"
                >
                  <div class="alert-body d-flex py-25">
                    <div class="d-flex">
                      <icon class="d-flex my-auto"
                            icon="info-circle"/>
                    </div>
                    <small class="ml-25 my-auto">
                      {{ $t('It does not take into account discounts applied to invoices') }}
                    </small>
                  </div>
                </b-alert>
              </div>
            </b-card-title>
            <b-card-text class="mb-0">
              <span v-if="isInvoiced">{{ $t('TotalInvoicedProducts:') }} {{ currency(totalInvoiced) }}</span>
              <span v-else>{{ $t('TotalOrderedProducts:') }} {{ currency(totalOrdered) }}</span>

            </b-card-text>
          </div>
          <div>
            <switch-input
                :model.sync="isInvoiced"
                name="Ordered/Invoiced"
            >
              <template #iconLeft>
                <icon icon="euro-sign"/>
              </template>
              <template #iconRight>
                <icon icon="shopping-cart"/>
              </template>

            </switch-input>
          </div>
        </b-card-header>

        <b-card-body class="">

          <line-chart
              :labels="xAxis"
              :series="yAxix"
              @click="chartClick"
          />

          <b-row
              class="overflow-auto"
              style="max-height: 300px"
          >
            <b-col cols="12"
                   v-for="(product, index) in articlesOrderByPercentDesc"
                   class="mt-1"
            >
              <b-row>
                <b-col cols="4">
                  {{ product.article }}
                </b-col>
                <b-col cols="5">
                  <b-progress
                      :key="index"
                      :value="(product.percent * 100)"
                      :id="'popover-'+index"
                  />
                  <b-popover
                      :target="'popover-'+index"
                      placement="bottom"
                      triggers="hover"
                  >
                    <template #title>
                      {{ product.article }}
                    </template>
                    <div>
                      <div class="d-flex justify-content-between">
                        <span>{{ $t('Quantity:') }}&nbsp;</span>
                        <span class="text-right">
                          <b>{{ product.quantity }}</b>
                        </span>
                      </div>
                      <div class="d-flex justify-content-between">
                        <span>{{ $t('PreTaxAmount:') }}&nbsp;</span>
                        <span class="text-right">
                          <b>{{ currency(product.preTaxAmount) }}</b>
                        </span>
                      </div>
                      <div class="d-flex justify-content-between">
                        <span>{{ $t('AverageUnitPrice:') }}&nbsp;</span>
                        <span class="text-right">
                          <b>{{ currency(product.average) }}</b>
                        </span>
                      </div>
                    </div>
                  </b-popover>
                </b-col>
                <b-col cols="3"
                       class="text-right">
                  {{ percent((product.percent * 100), 0) }}
                </b-col>
              </b-row>

            </b-col>
          </b-row>

        </b-card-body>
      </b-card>
    </transition>

    <!--    Detail modal-->
    <prompt-detail-articles
        :date="modalTitle"
        :isOpen.sync="isModalDetailOpen"
        :elementsToDetail="elementsToDetail"
    />


  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { useIndicators } from '../useIndicators'
import { capitalize, currency, percent } from '../../../utils/filter'

import SwitchInput from '../../input/Switch'
import useAPI from '../../../utils/useAPI'
import LineChart from '../../chart/Line'
import moment from 'moment'
import i18n from '@/libs/i18n'
import PromptDetailArticles from '../components/_promptDetailArticles'

export default {
  components: {
    SwitchInput,
    LineChart,
    PromptDetailArticles,
  },
  props: {
    start: {
      type: String,
      default: null
    },
    end: {
      type: String,
      default: null
    },
    isSales: {
      type: Boolean,
      default: false
    },
    employees: {
      type: Array,
      default: () => []
    }
    // isInvoiced: {
    //   type: Boolean,
    //   default: false
    // }
  },
  setup (props) {
    const { fetchExpensesIndicator, fetchSalesIndicator, expensesIndicator, salesIndicator } = useAPI()

    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const startLocal = ref(moment(props.start))
    const endLocal = ref(moment(props.end))
    const employeesLocal = ref(props.employees)
    const isModalDetailOpen = ref(false)
    const modalTitle = ref(null)
    const elementsToDetail = ref(null)

    const isInvoiced = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const expensesByMonths = computed(() => {
      return expensesIndicator.value.expensesByMonths
    })
    const salesByMonths = computed(() => {
      return salesIndicator.value.turnoverByMonths
    })

    const xAxis = computed(() => {
      let months = []

      if (typeof expensesByMonths.value !== 'undefined') {
        // Object.keys(expensesByMonths.value).forEach(em => months.push(capitalize(moment(em).format('MMMM YYYY'))))
        Object.keys(expensesByMonths.value).forEach(em => months.push(em))
      }

      return months
    })

    const yAxix = computed(() => {
      let output = []

      if (props.isSales) {
        if (isInvoiced.value) {
          Object.keys(salesByMonths.value).forEach(item => output.push(salesByMonths.value[item].products.invoicedTotal))
        } else {
          Object.keys(salesByMonths.value).forEach(item => output.push(salesByMonths.value[item].products.orderedTotal))
        }
      } else {
        if (isInvoiced.value) {
          Object.keys(expensesByMonths.value).forEach(item => output.push(expensesByMonths.value[item].products.invoicedTotal))
        } else {
          Object.keys(expensesByMonths.value).forEach(item => output.push(expensesByMonths.value[item].products.orderedTotal))
        }
      }

      return [
        {
          name: isInvoiced.value ? i18n.t('Invoiced') : i18n.t('Ordered'),
          data: output
        }]
    })

    const totalOrdered = computed(() => {
      let totalOrdered = 0

      if (props.isSales) {
        Object.keys(salesByMonths.value).forEach(item => totalOrdered += salesByMonths.value[item].products.orderedTotal)
      } else {
        Object.keys(expensesByMonths.value).forEach(item => totalOrdered += expensesByMonths.value[item].products.orderedTotal)
      }
      return totalOrdered
    })

    const totalInvoiced = computed(() => {
      let totalInvoiced = 0
      if (props.isSales) {
        Object.keys(salesByMonths.value).forEach(item => totalInvoiced += salesByMonths.value[item].products.invoicedTotal)
      } else {
        Object.keys(expensesByMonths.value).forEach(item => totalInvoiced += expensesByMonths.value[item].products.invoicedTotal)
      }
      return totalInvoiced
    })

    const articlesOrderByPercentDesc = computed(() => {
      let articlesOrderByPercentDesc = []

      if (props.isSales) {
        Object.keys(salesByMonths.value).forEach(item => {
          if (isInvoiced.value) {
            salesByMonths.value[item].products.invoiced.forEach(p => {
              populateArticlesOrderByPercentDesc(articlesOrderByPercentDesc, p)
            })
          } else {
            salesByMonths.value[item].products.ordered.forEach(p => {
              populateArticlesOrderByPercentDesc(articlesOrderByPercentDesc, p)
            })
          }
        })
      } else {
        Object.keys(expensesByMonths.value).forEach(item => {
          if (isInvoiced.value) {
            expensesByMonths.value[item].products.invoiced.forEach(p => {
              populateArticlesOrderByPercentDesc(articlesOrderByPercentDesc, p)
            })
          } else {
            expensesByMonths.value[item].products.ordered.forEach(p => {
              populateArticlesOrderByPercentDesc(articlesOrderByPercentDesc, p)
            })
          }
        })
      }

      articlesOrderByPercentDesc.forEach(ap => {
        if (isInvoiced.value) {
          ap.percent = (ap.preTaxAmount / totalInvoiced.value)
        } else {
          ap.percent = (ap.preTaxAmount / totalOrdered.value)
        }
      })

      articlesOrderByPercentDesc.sort((a, b) => b.percent - a.percent)

      return articlesOrderByPercentDesc
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(startLocal, () => {
      fetchIndicators()
    })

    watch(endLocal, () => {
      fetchIndicators()
    })

    watch(() => props.employees, val => {
      employeesLocal.value = val
      fetchIndicators()
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const fetchIndicators = () => {
      if (startLocal.value != null && endLocal.value != null) {
        fetchExpensesIndicator({
          start: moment(props.start).format('YYYY-MM-DD'),
          end: moment(props.end).format('YYYY-MM-DD'),
          employees: employeesLocal.value.map(e=>e.id)
        })

        fetchSalesIndicator({
          dates: {
            start: moment(props.start).format('YYYY-MM-DD'),
            end: moment(props.end).format('YYYY-MM-DD')
          }
        })
      }
    }

    const populateArticlesOrderByPercentDesc = (articlesOrderByPercentDesc, p) => {
      let quantity = 0
      let preTaxAmount = 0
      let unitPrices = 0
      p.quantity.forEach(q => quantity += parseFloat(q))
      p.preTaxAmount.forEach(pt => preTaxAmount += parseFloat(pt))
      p.unitPrices.forEach(u => unitPrices += parseFloat(u))

      let articleExistIndex = articlesOrderByPercentDesc.findIndex(ao => ao.article == p.article)
      if (articleExistIndex == -1) {
        // console.log(p)

        let output = {
          article: p.article,
          quantity: quantity,
          preTaxAmount: preTaxAmount,
          percent: p.percent,
          ponderateAverage: [],
          average: 0
        }
        for (let i = 0; i < p.quantity.length; i++) {
          output.ponderateAverage.push(p.preTaxAmount[i] / parseFloat(p.quantity[i]))
        }

        let average = 0
        output.ponderateAverage.forEach(pa => average += pa)
        output.average = average / p.quantity.length

        articlesOrderByPercentDesc.push(output)
      } else {
        for (let i = 0; i < p.quantity.length; i++) {
          articlesOrderByPercentDesc[articleExistIndex].ponderateAverage.push(p.preTaxAmount[i] / parseFloat(p.quantity[i]))
        }
        let average = 0
        articlesOrderByPercentDesc[articleExistIndex].ponderateAverage.forEach(pa => average += pa)
        articlesOrderByPercentDesc[articleExistIndex].average = average / articlesOrderByPercentDesc[articleExistIndex].ponderateAverage.length
        articlesOrderByPercentDesc[articleExistIndex].quantity += quantity
        articlesOrderByPercentDesc[articleExistIndex].preTaxAmount += preTaxAmount
        articlesOrderByPercentDesc[articleExistIndex].percent += p.percent
      }

      // console.log(articlesOrderByPercentDesc)
    }

    const chartClick = (index) => {
      elementsToDetail.value = []

      if (props.isSales) {
        if (isInvoiced.value) {
          Object.values(salesByMonths.value)[index].products.invoiced.forEach(p => {
            populateElementsToDetail(p)
          })
        } else {
          Object.values(salesByMonths.value)[index].products.ordered.forEach(p => {
            populateElementsToDetail(p)
          })
        }
      } else {
        if (isInvoiced.value) {
          Object.values(expensesByMonths.value)[index].products.invoiced.forEach(p => {
            populateElementsToDetail(p)
          })
        } else {
          Object.values(expensesByMonths.value)[index].products.ordered.forEach(p => {
            populateElementsToDetail(p)
          })
        }
      }

      elementsToDetail.value.sort((a, b) => b.preTaxAmount - a.preTaxAmount)

      modalTitle.value = Object.keys(expensesByMonths.value)[index]
      isModalDetailOpen.value = true
    }

    const populateElementsToDetail = (p) => {
      let quantity = 0
      let preTaxAmount = 0
      let unitPrices = 0
      p.quantity.forEach(q => quantity += parseFloat(q))
      p.preTaxAmount.forEach(p => preTaxAmount += p)
      p.unitPrices.forEach(u => unitPrices += u)

      let articleExistIndex = elementsToDetail.value.findIndex(ao => ao.article == p.article)
      if (articleExistIndex == -1) {
        elementsToDetail.value.push({
          article: p.article,
          quantity: quantity,
          preTaxAmount: preTaxAmount,
          average: preTaxAmount / quantity
        })
      } else {
        elementsToDetail.value[articleExistIndex].quantity += quantity
        elementsToDetail.value[articleExistIndex].preTaxAmount += preTaxAmount
        elementsToDetail.value[articleExistIndex].percent += p.percent
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchExpensesIndicator({
      start: moment(props.start).format('YYYY-MM-DD'),
      end: moment(props.end).format('YYYY-MM-DD'),
      employees: employeesLocal.value.map(e=>e.id)
    })

    fetchSalesIndicator({
      dates: {
        start: moment(props.start).format('YYYY-MM-DD'),
        end: moment(props.end).format('YYYY-MM-DD')
      }
    })

    return {
      // Components
      capitalize,
      currency,
      percent,

      // Data
      startLocal,
      endLocal,
      isModalDetailOpen,
      modalTitle,
      elementsToDetail,
      isInvoiced,

      // Computed
      expensesIndicator,
      salesByMonths,
      salesIndicator,
      // purchaseByMonths,
      // salesByMonths,
      xAxis,
      yAxix,
      totalOrdered,
      totalInvoiced,
      articlesOrderByPercentDesc,

      // Methods
      chartClick,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    start: function (val) {
      this.startLocal = moment(val)
    },
    end: function (val) {
      this.endLocal = moment(val)
    },
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>